import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import axios from "axios";
import profback from "../static/back.jpg";
import { UserContext } from "../context/UserContext.js";

export default function ProfileMenu() {
  function logout() {
    localStorage.removeItem("userInfo");
    axios({
      url: `api/v1/${x}/logout`,
      // withCredentials: true,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");
  }

  const { userInfo } = useContext(UserContext);
  let x = null;
  if (userInfo) {
    if (userInfo.farmName) {
      x = "farm";
    }
    if (userInfo.logisticsName) {
      x = "logistics";
    }
    if (userInfo.storeName) {
      x = "store";
    }
    if (userInfo.companyName) {
      x = "company";
    }
    if (userInfo.name) {
      x = "user";
    }
  }

  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 5000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  const notify = () => {
    toastr.warning(
      "check back after some time",
      "Sorry this functionality is not yet available for use."
    );
  };

  return (
    <div className="profilemenu">
      {userInfo && (
        <>
          <img
            src={userInfo.avatar ? userInfo.avatar : profback}
            alt="profile background"
            className="profback"
          />
          <img
            src={userInfo.avatar ? userInfo.avatar : profback}
            alt="profile background"
            className="profimg"
          />
          <div className="profName">
            {userInfo.farmName && (
              <p className="profName">{userInfo.farmName}</p>
            )}
            {userInfo.storeName && (
              <p className="profName">{userInfo.storeName}</p>
            )}
            {userInfo.companyName && (
              <p className="profName">{userInfo.companyName}</p>
            )}
            {userInfo.logisticsName && (
              <p className="profName">{userInfo.logisticsName}</p>
            )}
            <p className="profName">@{userInfo.username}</p>

            <div className="manuopt"></div>
            {userInfo.farmName && (
              <div>
                <Link to="/myprofile">
                  <p className="manuopt">Dashboard</p>
                </Link>
                <div className="subopt">
                  <Link to="/myprofile/MyProducts">
                    <p className="manuopt1">My Products</p>
                  </Link>
                  <Link to="/myprofile/farmOrder">
                    <p className="manuopt1">Orders</p>
                  </Link>
                  <Link to="/unavalables">
                    <p className="manuopt1">What People Need</p>
                  </Link>
                  <Link to="/myprofile/Notification">
                    <p className="manuopt1">Notifications</p>
                  </Link>
                  <Link to="/chat">
                    <p className="manuopt1">Chat</p>
                  </Link>
                  <Link to="/addaccount">
                    <p className="manuopt1">Add Account Details</p>
                  </Link>
                  {/* <Link onClick={() => handleContentChange("OrderList")}>
                    <p className="manuopt1">Orders</p>
                  </Link> */}
                  {/* <Link onClick={() => handleContentChange("OrderDetail")}>
                    <p className="manuopt1">Orders Detail</p>
                  </Link> */}
                  {/* <Link onClick={notify}>
                    <p className="manuopt1">Wallet</p>
                  </Link> */}
                </div>
                <p className="manuopt">My Purchases</p>
                <div className="subopt">
                  <Link to="/myprofile/MyCart">
                    <p className="manuopt1">My Cart</p>
                  </Link>

                  <Link to="/myprofile/OrderList">
                    <p className="manuopt1">Store Orders</p>
                  </Link>
                  <Link to="/myprofile/CompanyOrderList">
                    <p className="manuopt1">Company Orders</p>
                  </Link>
                  <Link to="/myprofile/FarmOrderList">
                    <p className="manuopt1">Farm Orders</p>
                  </Link>
                  <Link to="/myprofile/logisticsOrderList">
                    <p className="manuopt1">Logistics Orders</p>
                  </Link>
                </div>
              </div>
            )}
            {userInfo.storeName && (
              <div>
                <Link to="/myprofile">
                  <p className="manuopt">Dashboard</p>
                </Link>
                <div className="subopt">
                  <Link to="/myprofile/MyProducts">
                    <p className="manuopt1">My Products</p>
                  </Link>
                  <Link to="/myprofile/AddLocation">
                    <p className="manuopt1">Store Locations</p>
                  </Link>
                  <Link to="/myprofile/storeOrder">
                    <p className="manuopt1">Orders</p>
                  </Link>
                  <Link to="/uploadstoreproduct">
                    <p className="manuopt1">Upload new Product</p>
                  </Link>
                  <Link to="/chat">
                    <p className="manuopt1">Chat</p>
                  </Link>
                  <Link to="/myprofile/Notification">
                    <p className="manuopt1">Notifications</p>
                  </Link>
                  <Link to="/addaccount">
                    <p className="manuopt1">Add Account Details</p>
                  </Link>
                  {/* <Link onClick={() => handleContentChange("OrderList")}>
                    <p className="manuopt1">Orders</p>
                  </Link> */}
                  {/* <Link onClick={() => handleContentChange("OrderDetail")}>
                    <p className="manuopt1">Orders Detail</p>
                  </Link> */}
                  {/* <Link onClick={notify}>
                    <p className="manuopt1">Wallet</p>
                  </Link> */}
                </div>
                <p className="manuopt">My Purchases</p>
                <div className="subopt">
                  <Link to="/myprofile/MyCart">
                    <p className="manuopt1">My Cart</p>
                  </Link>
                  <Link to="/myprofile/OrderList">
                    <p className="manuopt1">Store Orders</p>
                  </Link>
                  <Link to="/myprofile/CompanyOrderList">
                    <p className="manuopt1">Company Orders</p>
                  </Link>
                  <Link to="/myprofile/FarmOrderList">
                    <p className="manuopt1">Farm Orders</p>
                  </Link>
                  <Link to="/myprofile/logisticsOrderList">
                    <p className="manuopt1">Logistics Orders</p>
                  </Link>
                </div>

                <Link to="/myprofile/edit">
                  <p className="manuopt">Edit Profile</p>
                </Link>
              </div>
            )}
            {userInfo.companyName && (
              <div>
                <Link to="/myprofile">
                  <p className="manuopt">Dashboard</p>
                </Link>
                <div className="subopt">
                  <Link to="/myprofile/MyProducts">
                    <p className="manuopt1">My Products</p>
                  </Link>
                  <Link to="/myprofile/AddLocation">
                    <p className="manuopt1">Company Locations</p>
                  </Link>
                  <Link to="/myprofile/companyOrder">
                    <p className="manuopt1">Orders</p>
                  </Link>
                  <Link to="/uploadcompanyproduct">
                    <p className="manuopt1">Upload new Product</p>
                  </Link>
                  <Link to="/addaccount">
                    <p className="manuopt1">Add Account Details</p>
                  </Link>
                  <Link to="/chat">
                    <p className="manuopt1">Chat</p>
                  </Link>
                  <Link to="/myprofile/Notification">
                    <p className="manuopt1">Notifications</p>
                  </Link>
                  {/* <Link to="/addaccount">
                        <p className="manuopt1">Add Account Details</p>
                  </Link> */}
                  {/* <Link onClick={() => handleContentChange("OrderList")}>
                    <p className="manuopt1">Orders</p>
                  </Link> */}
                  {/* <Link onClick={() => handleContentChange("OrderDetail")}>
                    <p className="manuopt1">Orders Detail</p>
                  </Link> */}
                  {/* <Link onClick={notify}>
                    <p className="manuopt1">Wallet</p>
                  </Link> */}
                </div>
                <p className="manuopt">My Purchases</p>
                <div className="subopt">
                  <Link to="/myprofile/MyCart">
                    <p className="manuopt1">My Cart</p>
                  </Link>
                  <Link to="/myprofile/OrderList">
                    <p className="manuopt1">Store Orders</p>
                  </Link>
                  <Link to="/myprofile/CompanyOrderList">
                    <p className="manuopt1">Company Orders</p>
                  </Link>
                  <Link to="/myprofile/FarmOrderList">
                    <p className="manuopt1">Farm Orders</p>
                  </Link>
                  <Link to="/myprofile/logisticsOrderList">
                    <p className="manuopt1">Logistics Orders</p>
                  </Link>
                </div>
              </div>
            )}
            {userInfo.logisticsName && (
              <div>
                <Link to="/myprofile">
                  <p className="manuopt">Dashboard</p>
                </Link>
                <div className="subopt">
                  <Link to="/myprofile/MyVehicles">
                    <p className="manuopt1">My Vehicles</p>
                  </Link>
                  <Link to="/myprofile/AddLocation">
                    <p className="manuopt1">Logistics Locations</p>
                  </Link>
                  <Link to="/myprofile/logisticsOrder">
                    <p className="manuopt1">Store Bookings</p>
                  </Link>
                  <Link to="/myprofile/directOrder">
                    <p className="manuopt1">Direct Bookings</p>
                  </Link>
                  <Link to="/chat">
                    <p className="manuopt1">Chat</p>
                  </Link>
                  <Link to="/myprofile/Notification">
                    <p className="manuopt1">Notifications</p>
                  </Link>
                  <Link to="/addaccount">
                    <p className="manuopt1">Add Account Details</p>
                  </Link>
                  {/* <Link onClick={() => handleContentChange("OrderList")}>
                    <p className="manuopt1">Orders</p>
                  </Link> */}
                  {/* <Link onClick={() => handleContentChange("OrderDetail")}>
                    <p className="manuopt1">Orders Detail</p>
                  </Link> */}
                  {/* <Link onClick={notify}>
                    <p className="manuopt1">Wallet</p>
                  </Link> */}
                </div>
                <p className="manuopt">My Purchases</p>
                <div className="subopt">
                  <Link to="/myprofile/MyCart">
                    <p className="manuopt1">My Cart</p>
                  </Link>
                  <Link to="/myprofile/OrderList">
                    <p className="manuopt1">Store Orders</p>
                  </Link>
                  <Link to="/myprofile/FarmOrderList">
                    <p className="manuopt1">Farm Orders</p>
                  </Link>
                  <Link to="/myprofile/logisticsOrderList">
                    <p className="manuopt1">Logistics Orders</p>
                  </Link>
                </div>

                <Link to="/myprofile/edit">
                  <p className="manuopt">Edit Profile</p>
                </Link>
              </div>
            )}
            {userInfo.name && (
              <div>
                <Link to="/myprofile">
                  <p className="manuopt">Dashboard</p>
                </Link>
                <div className="subopt">
                  {/* <Link>
                    <p className="manuopt1">My Products</p>
                  </Link> */}
                  <Link to="/myprofile/MyCart">
                    <p className="manuopt1">My Cart</p>
                  </Link>
                  <Link to="/myprofile/OrderList">
                    <p className="manuopt1">Store Orders</p>
                  </Link>
                  <Link to="/myprofile/FarmOrderList">
                    <p className="manuopt1">Farm Orders</p>
                  </Link>
                  <Link to="/myprofile/logisticsOrderList">
                    <p className="manuopt1">Logistics Orders</p>
                  </Link>
                  <Link to="/chat">
                    <p className="manuopt1">Chat</p>
                  </Link>
                  <Link to="/myprofile/Notification">
                    <p className="manuopt1">Notifications</p>
                  </Link>
                  <Link to="/addaccount">
                    <p className="manuopt1">Add Account Details</p>
                  </Link>
                  {/* <Link onClick={() => handleContentChange("OrderList")}>
                    <p className="manuopt1">Orders</p>
                  </Link> */}
                  {/* <Link onClick={() => handleContentChange("OrderDetail")}>
                    <p className="manuopt1">Orders Detail</p>
                  </Link> */}
                  {/* <Link onClick={() => handleContentChange("OrderDetail")}>
                    <p className="manuopt1">Orders Detail</p>
                  </Link> */}
                  {/* <Link onClick={notify}>
                    <p className="manuopt1">Wallet</p>
                  </Link> */}
                </div>
              </div>
            )}

            <Link onClick={notify}>
              <p className="manuopt">Profile Settings</p>
            </Link>
            <Link to="/tandc">
              <p className="manuopt1">Terms and Condition</p>
            </Link>
            <Link to="/return">
              <p className="manuopt1">Return Policy</p>
            </Link>
            <Link to="/faqs">
              <p className="manuopt1">Frequently asked questions (FAQs)</p>
            </Link>
            <Link to="/referral">
              <p className="manuopt1">Referral Terms and Benefits</p>
            </Link>
            <Link onClick={logout} className="menuitemhere" to="/">
              <p className="manuoptlog">Logout</p>
              <BiLogOut />
            </Link>
          </div>
        </>
      )}
    </div>
  );
}
