import { useEffect, useState } from "react";
import axios from "axios";
// import { Link } from "react-router-dom";
// import { FiEdit } from "react-icons/fi";
import EachUnavailable from "../Components/EachUnavailable";
import SideNav from "../Components/SideNav";
import "../styles/products.css";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import FMFooter from "../Components/FMFooter";
import SearchOptions from "../Components/SearchOptions";

export default function Unavailables() {
  const [farmProducts, setFarmProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `api/v1/unavailable?pageNumber=${page}&keyword=${searchTerm}`
        );
        setFarmProducts(response.data.products);
        // If the current page is greater than the total number of pages,
        // reset the page to 1 to ensure products are displayed correctly.
        if (page > response.data.pages) {
          setPage(1);
        }
        setTotalPages(response.data.pages);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, [page, searchTerm]);

  const onTop = () => {
    window.scrollTo(0, 0);
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
      onTop();
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
      onTop();
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div>
      <Navbar />
      <SearchOptions />
      <div>
        <input
          type="text"
          placeholder="Search products by name"
          value={searchTerm}
          onChange={handleSearchChange}
          className="searchField"
        />
      </div>
      {isLoading ? (
        <div className="loading">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <div className="prodPag">
          <div className="userpages">
            <div className="sideNavs">
              <SideNav />
            </div>
            <div className="listProducts">
              {farmProducts.map((product) => (
                <EachUnavailable {...product} key={product._id} />
              ))}
            </div>
          </div>
          <div className="pagination">
            <button onClick={handlePrevPage} disabled={page === 1}>
              Previous
            </button>
            <span>
              Page {page} of {totalPages}
            </span>
            <button onClick={handleNextPage} disabled={page === totalPages}>
              Next
            </button>
          </div>
        </div>
      )}

      {/* <Link to='/uploadunavailable' className='uploadproduct'>
          <FiEdit size={14} />
          <p>order unavailable Product</p>
        </Link> */}
      <div className="mfooter">
        <FMFooter />
      </div>
      <Footer className="footer" />
    </div>
  );
}
