import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { CiLocationOn } from "react-icons/ci";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";

import { UserContext } from "../context/UserContext";

function EachStoreLocationAdmin({ _id, address, city }) {
  const { setUserInfo, userInfo } = useContext(UserContext);
  const navigate = useNavigate();

  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 5000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  const notify = (message) => {
    toastr.success(message, "Success Message");
  };

  const displayError = (error) => {
    toastr.error(error, "Location could not deleted.");
  };

  const handleDelete = async (e) => {
    try {
      await axios.delete(`/api/v1/store/location/${_id}`, {
        headers: {
          // Accept: "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const profileResponse = await axios({
        url: "/api/v1/store/profile",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        method: "GET",
      });

      const userInfoData = profileResponse.data;
      setUserInfo(userInfoData);

      navigate("/myprofile/AddLocation");
      notify(`You just deleted ${address}`);
    } catch (error) {
      displayError(error.response.data.message);
    }
  };

  return (
    <div className="eachLocation">
      <CiLocationOn size={100} />
      <div className="locationTexts">
        <p className="farmname">
          {userInfo && userInfo.storeName
            ? userInfo.storeName
            : "Unknown Store"}
        </p>
        <p className="productname"> Address: {address}</p>
        <p className="productname"> City: {city}</p>
        <div className="editoptions">
          <Link to={`/editstorelocation/${_id}`} className="editproduct">
            <FiEdit />
            <p>Edit</p>
          </Link>
          <Link className="deletproduct" onClick={handleDelete}>
            <AiOutlineDelete />
            <p>Delete</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default EachStoreLocationAdmin;
