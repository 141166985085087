import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import "../styles/EditStore.css"; // Import your shared CSS file for styling

const daysOfWeek = [
  { name: "Monday", id: "monday" },
  { name: "Tuesday", id: "tuesday" },
  { name: "Wednesday", id: "wednesday" },
  { name: "Thursday", id: "thursday" },
  { name: "Friday", id: "friday" },
  { name: "Saturday", id: "saturday" },
  { name: "Sunday", id: "sunday" },
];

const EditCompany = () => {
  const navigate = useNavigate();
  const { userInfo } = useContext(UserContext);
  const [companyData, setCompanyData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [companyHours, setCompanyHours] = useState([]);
  const [isCompanyClosed, setIsCompanyClosed] = useState(false);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 5000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get("/api/v1/company/profile", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setCompanyData(response.data);
        setIsCompanyClosed(response.data.closed);
        setCompanyHours(response.data.schedule || []);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching company data:", error);
        toastr.error("Failed to load company data. Please try again.");
        setIsLoading(false);
      }
    };

    fetchCompanyData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let formData = new FormData();
      formData.append("name", companyData.companyName);
      formData.append("companyAddress", companyData.companyAddress);
      formData.append("city", companyData.city);
      formData.append("username", companyData.username);
      formData.append("phoneNumber", companyData.phoneNumber);
      formData.append("email", companyData.email);
      formData.append("avatar", avatar ? avatar[0] : companyData.avatar);
      formData.append("closed", isCompanyClosed);
      formData.append(
        "coverPhoto",
        coverPhoto ? coverPhoto[0] : companyData.coverPhoto
      );
      formData.append(
        "schedule",
        JSON.stringify(companyHours.map(convertTimeTo24Hours))
      );

      const response = await axios.put("/api/v1/company/profile", formData, {
        headers: {
          Accept: "multipart/form-data",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      toastr.success("Company details updated successfully!");
      setIsLoading(false);
      navigate("/myprofile");
    } catch (error) {
      console.error("Error updating company details:", error);
      toastr.error("Failed to update company details. Please try again.");
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e, type) => {
    if (type === "avatar") {
      setAvatar(e.target.files);
    } else if (type === "coverPhoto") {
      setCoverPhoto(e.target.files);
    }
  };

  const handleCompanyHoursChange = (day, field, value) => {
    const updatedCompanyHours = [...companyHours];
    const index = updatedCompanyHours.findIndex((hour) => hour.day === day);
    if (index !== -1) {
      updatedCompanyHours[index][field] = value;
    } else {
      updatedCompanyHours.push({ day, openingTime: "", closingTime: "" });
      updatedCompanyHours[updatedCompanyHours.length - 1][field] = value;
    }
    setCompanyHours(updatedCompanyHours);
  };

  const convertTimeTo24Hours = (hour) => {
    if (!hour.openingTime || !hour.closingTime) return hour;
    const openingTime24 = new Date(`2000-01-01T${hour.openingTime}`);
    const closingTime24 = new Date(`2000-01-01T${hour.closingTime}`);
    return {
      ...hour,
      openingTime: openingTime24.toTimeString().slice(0, 5),
      closingTime: closingTime24.toTimeString().slice(0, 5),
    };
  };

  return (
    <div className="edit-store">
      <div
        className="loading-overlay"
        style={{ display: isLoading ? "block" : "none" }}
      >
        <ClipLoader
          css={override}
          size={150}
          color={"#123abc"}
          loading={isLoading}
        />
        <p>Loading...</p>
      </div>
      <form onSubmit={handleSubmit} className="edit-store-form">
        <h2>Edit Company Profile</h2>
        <div className="form-group">
          <label>Company Name</label>
          <input
            type="text"
            name="companyName"
            value={companyData.companyName || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Company Address</label>
          <input
            type="text"
            name="companyAddress"
            value={companyData.companyAddress || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>City</label>
          <input
            type="text"
            name="city"
            value={companyData.city || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Username</label>
          <input
            type="text"
            name="username"
            value={companyData.username || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Phone Number</label>
          <input
            type="text"
            name="phoneNumber"
            value={companyData.phoneNumber || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={companyData.email || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Avatar</label>
          <input
            type="file"
            onChange={(e) => handleFileChange(e, "avatar")}
            className="custom-file-input"
            accept="image/*"
          />
          {companyData.avatar && (
            <div className="image-preview-container">
              <div className="image-preview">
                <img src={companyData.avatar} alt="Company Avatar" />
              </div>
            </div>
          )}
        </div>
        <div className="form-group">
          <label>Cover Photo</label>
          <input
            type="file"
            onChange={(e) => handleFileChange(e, "coverPhoto")}
            className="custom-file-input"
            accept="image/*"
          />
          {companyData.coverPhoto && (
            <div className="image-preview-container">
              <div className="image-preview">
                <img src={companyData.coverPhoto} alt="Company Cover Photo" />
              </div>
            </div>
          )}
        </div>
        <div className="form-group">
          <label>Company Hours</label>
          {daysOfWeek.map((day) => (
            <div key={day.id} className="store-hour">
              <span>{day.name}</span>
              <input
                type="time"
                value={
                  companyHours.find((hour) => hour.day === day.name)
                    ?.openingTime || ""
                }
                onChange={(e) =>
                  handleCompanyHoursChange(
                    day.name,
                    "openingTime",
                    e.target.value
                  )
                }
              />
              <input
                type="time"
                value={
                  companyHours.find((hour) => hour.day === day.name)
                    ?.closingTime || ""
                }
                onChange={(e) =>
                  handleCompanyHoursChange(
                    day.name,
                    "closingTime",
                    e.target.value
                  )
                }
              />
            </div>
          ))}
        </div>
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              checked={isCompanyClosed}
              onChange={(e) => setIsCompanyClosed(e.target.checked)}
            />
            Close Company
          </label>
        </div>
        <button type="submit" disabled={isLoading} className="updatestore">
          {isLoading ? (
            <span className="loading-spinner"></span>
          ) : (
            "Update Company Profile"
          )}
        </button>
      </form>
    </div>
  );
};

export default EditCompany;
