import { css } from "@emotion/react";
import React from "react";
import { ClipLoader } from "react-spinners";

const CustomSpinner = ({ isLoading }) => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <div
      className="loading-overlay"
      style={{
        display: isLoading ? "flex " : "none",
        // marginTop: 80,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ClipLoader
        // css={override}
        size={150}
        color={"#397846"}
        loading={isLoading}
      />
      <p>Loading...</p>
    </div>
  );
};

export default CustomSpinner;
