import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Landingpage from "./Pages/Landingpage";
import "./App.css";
import Signup from "./Pages/Signup";
import Signin from "./Pages/Signin";
import MyProfile from "./Pages/MyProfile";
import TAndC from "./Pages/TAndC";
import Referral from "./Pages/Referral";
import FAQs from "./Pages/FAQs";
import Return from "./Pages/Return";
import FarmUpload from "./Components/FarmUpload";
import LogisticsUpload from "./Components/LogisticsUpload";
import StoreUpload from "./Components/StoreUpload";
import CompanyUpload from "./Components/CompanyUpload";
import FarmProductList from "./Pages/FarmProductList";
import StoreSearch from "./Pages/StoreSearch";
import CompanySearch from "./Pages/CompanySearch";
import axios from "axios";
import { UserContextProvider } from "./context/UserContext";
import FarmPEdit from "./Components/FarmPEdit";
import StorePEdit from "./Components/StorePEdit";
import CompanyPEdit from "./Components/CompanyPEdit";
import LogisticsVEdit from "./Components/LogisticsVEdit";
import StorePage from "./Pages/StorePage";
import CompanyPage from "./Pages/CompanyPage";
import FarmProductDetail from "./Pages/FarmProductDetail";
import FarmUnavailable from "./Pages/FarmUnavailable";
import StoreProductPage from "./Pages/StoreProductPage";
import CompanyProductPage from "./Pages/CompanyProductPage";
import LogisticsPage from "./Pages/LogisticsPage";
import LogisticsBook from "./Components/LogisticsBook";
import OrderDetail from "./Pages/OrderDetail";
import UnavailableUpload from "./Components/UnavailableUpload";
import CompanyOrderDetail from "./Pages/CompanyOrderDetail";
import COrderDetail from "./Pages/COrderDetail";
import CartDetail from "./Pages/CartDetail";
import LogisticsOrderDetail from "./Pages/LogisticsOrderDetail";
import FarmOrderDetail from "./Pages/FarmOrderDetail";
import Withdrawal from "./Components/Withdrawal";
import Deposit from "./Components/Deposit";
import AddAccountDetail from "./Components/AddAccountDetail";
import Chat from "./Pages/Chat";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import RecoverAccount from "./Pages/RecoverAccount";
import Unavailables from "./Pages/Unavailables";
import EditStoreLocation from "./Components/EditStorLocation";
import EditCompanyLocation from "./Components/EditCompanyLocation";
import RateProducts from "./Components/StoreOrderRating";

const isAuthenticated = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  return !!userInfo && !!userInfo?._id;
};

function App() {
  // axios.defaults.baseURL = "https://farmyapp.onrender.com"; // test url
  axios.defaults.baseURL = "https://farmyappbackend.onrender.com"; // production url
  // axios.defaults.baseURL = "http://127.0.0.1:3500";
  axios.defaults.withCredentials = true;
  return (
    <div className="App">
      <BrowserRouter>
        <UserContextProvider>
          <Routes>
            <Route path="/" exact element={<Landingpage />} />
            <Route
              path="/signin"
              element={
                isAuthenticated() ? <Navigate to="/myprofile" /> : <Signin />
              }
            />
            <Route
              path="/signup"
              element={
                isAuthenticated() ? <Navigate to="/myprofile" /> : <Signup />
              }
            />

            <Route path="/chat/:chatId" element={<Chat />} />

            <Route path="/forgot" element={<ForgotPassword />} />

            <Route path="/reset" element={<ResetPassword />} />
            <Route path="/recover-account" element={<RecoverAccount />} />

            <Route path="/chat" element={<Chat />} />
            <Route path="/uploadunavailable" element={<UnavailableUpload />} />
            <Route path="/unavailables" element={<Unavailables />} />
            <Route
              path="/signup/:referral"
              element={
                isAuthenticated() ? <Navigate to="/myprofile" /> : <Signup />
              }
            />
            <Route path="/order/:orderId" element={<OrderDetail />} />
            <Route
              path="/companyorder/:orderId"
              element={<CompanyOrderDetail />}
            />
            <Route path="/corder/:orderId" element={<COrderDetail />} />
            <Route path="/cart/:cartId" element={<CartDetail />} />
            <Route path="/farmorder/:orderId" element={<FarmOrderDetail />} />
            <Route
              path="/farmersunavailable/:id"
              element={<FarmUnavailable />}
            />
            <Route
              path="/logisticsorder/:orderId"
              element={<LogisticsOrderDetail />}
            />
            <Route path="/myprofile/:contentType" element={<MyProfile />} />
            <Route path="/myprofile" element={<MyProfile />} />
            <Route path="/tandc" element={<TAndC />} />
            <Route path="/referral" element={<Referral />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/return" element={<Return />} />
            <Route path="/uploadfarmproduct" element={<FarmUpload />} />
            <Route path="/uploadstoreproduct" element={<StoreUpload />} />
            <Route path="/uploadcompanyproduct" element={<CompanyUpload />} />
            <Route path="/farmersmarket" element={<FarmProductList />} />
            <Route path="/farmersmarket/:id" element={<FarmProductDetail />} />
            <Route path="/deposit" element={<Deposit />} />
            <Route path="/addaccount" element={<AddAccountDetail />} />
            <Route path="/withdrawal" element={<Withdrawal />} />
            <Route path="/stores" element={<StoreSearch />} />
            <Route path="/companies" element={<CompanySearch />} />
            <Route path="/store/:slug" element={<StorePage />} />
            <Route path="/company/:slug" element={<CompanyPage />} />
            <Route path="/rating/:id" element={<RateProducts />} />
            <Route
              path="/store/:slug/:prodSlug"
              element={<StoreProductPage />}
            />
            <Route
              path="/company/:slug/:prodSlug"
              element={<CompanyProductPage />}
            />
            <Route path="/logistics" element={<LogisticsPage />} />
            <Route
              path="/logistics/:logisticsSlug"
              element={<LogisticsBook />}
            />
            <Route
              path="/uploadlogisticsvehicle"
              element={<LogisticsUpload />}
            />
            <Route path="/editfarmproduct/:id" element={<FarmPEdit />} />
            <Route path="/editstoreproduct/:id" element={<StorePEdit />} />
            <Route
              path="/editstorelocation/:id"
              element={<EditStoreLocation />}
            />
            <Route
              path="/editcompanylocation/:id"
              element={<EditCompanyLocation />}
            />
            <Route path="/editcompanyproduct/:id" element={<CompanyPEdit />} />
            <Route
              path="/editlogisticsvehicle/:slug"
              element={<LogisticsVEdit />}
            />

            <Route path="" component={<Landingpage />} />
          </Routes>
        </UserContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
