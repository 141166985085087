import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { io } from "socket.io-client";

import { UserContext } from "../context/UserContext";
import { Link, useLocation } from "react-router-dom";

// import profback from "../static/back.jpg";
import loho from "../static/FARMY EMB green..png";
import { MdAgriculture, MdStoreMallDirectory } from "react-icons/md";
import { BsPerson, BsCart2 } from "react-icons/bs";
import { BiLogOut } from "react-icons/bi";
import { GoOrganization } from "react-icons/go";
import { AiOutlineCar, AiOutlineMenu, AiFillCloseCircle } from "react-icons/ai";
import { IoMdNotificationsOutline } from "react-icons/io";
import { IoChatbubblesOutline } from "react-icons/io5";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import "../styles/navbar.css";
import { I0_LINK } from "../config";

// window.addEventListener('scroll', () => {
//   document.querySelector('nav').classList.toggle('window-scroll', window.scrollY > 0)
// })

function Navbar() {
  const location = useLocation();
  const { userInfo } = useContext(UserContext);
  const [notificationCount, setNotificationCount] = useState(0);
  const socket = useRef();
  const io_link = I0_LINK;
  useEffect(() => {
    socket.current = io(io_link);
  }, [io_link]);

  useEffect(() => {
    // Listen for new notifications
    socket.current.on(`notification_${userInfo._id}`, (newNotification) => {
      // Increment notification count when a new notification arrives
      setNotificationCount((prevCount) => prevCount + 1);
    });

    // Get initial notification count when the component mounts
    fetchNotificationCount();

    return () => {
      socket.current.disconnect();
    };
  }, [socket, userInfo._id]);

  const fetchNotificationCount = async () => {
    try {
      // Fetch initial notification count from the server
      const response = await axios.get("/api/v1/notifications/count", {
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setNotificationCount(response.data.unreadCount);
    } catch (error) {
      console.error("Error fetching notification count:", error);
    }
  };

  let x = null;

  if (userInfo) {
    if (userInfo.farmName) {
      x = "farm";
    }
    if (userInfo.logisticsName) {
      x = "logistics";
    }
    if (userInfo.storeName) {
      x = "store";
    }
    if (userInfo.companyName) {
      x = "company";
    }
    if (userInfo.name) {
      x = "user";
    }
  }

  function logout() {
    localStorage.removeItem("userInfo");
    axios({
      url: `api/v1/${x}/logout`,
      // withCredentials: true,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then(() => {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("token");
        // Redirect to the login page after successful logout
        window.location.href = "/signin";
      })
      .catch((error) => {
        console.error(error);
        // Handle error if necessary
      });
  }

  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 5000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  const notify = () => {
    toastr.warning(
      "check back after some time",
      "Sorry this functionality is not yet available for use."
    );
  };

  const [openMenu, setopenMenu] = useState(false);

  const isAuthenticated = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    return !!userInfo && userInfo.username;
  };

  return (
    <nav>
      <div className="navbar1">
        <div className="menu_icon1">
          <AiOutlineMenu onClick={() => setopenMenu(true)} />
        </div>
        <Link to="/stores">
          <img src={loho} alt="FarmyApp" className="navlogoi" />
        </Link>
        {isAuthenticated() && (
          <>
            <div className="menuitemM">
              <Link
                className={`menuitem ${
                  location.pathname === "/myprofile/MyCart" ? "selected" : ""
                }`}
                to="/myprofile/MyCart"
              >
                <BsCart2 className="menuicon" size={30} />
                <div className="menuident">My Cart</div>
              </Link>
              <Link
                className={`menuitem notification ${
                  location.pathname === "/notification" ? "selected" : ""
                }`}
                to="/myprofile/Notification"
              >
                {/* <div className="notification-icon">
              <IoMdNotificationsOutline className="menuicon" size={30} />
              {notificationCount > 0 && (
                <div className="notifyHead">{notificationCount}</div>
              )}
            </div> */}
                <IoMdNotificationsOutline className="menuicon" size={30} />{" "}
                <div className="notifyHead">{notificationCount}</div>
                <div className="menuident">Notification</div>
              </Link>
            </div>
          </>
        )}
      </div>
      <div className="navbar ">
        <Link to="/stores">
          <img src={loho} alt="FarmyApp" className="navlogoi" />
        </Link>
        <div className="menuitems">
          <Link
            className={`menuitem ${
              location.pathname === "/stores" ? "selected" : ""
            }`}
            to="/stores"
          >
            <MdStoreMallDirectory
              className={`menuicon ${
                location.pathname === "/stores" ? "selected" : ""
              }`}
              size={30}
            />
            <div className="menuident">Search Store</div>
          </Link>
          <Link
            className={`menuitem ${
              location.pathname === "/farmersmarket" ? "selected" : ""
            }`}
            to={"/farmersmarket"}
          >
            <MdAgriculture
              className={`menuicon ${
                location.pathname === "/farmersmarket" ? "selected" : ""
              }`}
              size={30}
            />
            <div className="menuident">Farmers' Market</div>
          </Link>
          <Link className="menuitem" to="/logistics">
            <AiOutlineCar
              className={`menuicon ${
                location.pathname === "/logistics" ? "selected" : ""
              }`}
              size={30}
            />
            <div className="menuident">Logistics</div>
          </Link>
          <Link
            className={`menuitem ${
              location.pathname === "/companies" ? "selected" : ""
            }`}
            to="/companies"
          >
            <GoOrganization
              className={`menuicon ${
                location.pathname === "/companies" ? "selected" : ""
              }`}
              size={30}
            />
            <div className="menuident">Wholesalers</div>
          </Link>
          {isAuthenticated() && (
            <>
              <Link className="menuitem" to="/myprofile/MyCart">
                <BsCart2
                  className={`menuicon ${
                    location.pathname === "/myprofile/MyCart" ? "selected" : ""
                  }`}
                  size={30}
                />
                <div className="menuident">My Cart</div>
              </Link>

              <Link
                className="menuitem notification"
                to="/myprofile/Notification"
                style={{
                  position: "relative",
                }}
              >
                <IoMdNotificationsOutline className="menuicon" size={30} />{" "}
                <div className="notifyHead">{notificationCount}</div>
                <div className="menuident">Notification</div>
              </Link>
              <Link className="menuitem" to="/myprofile">
                <BsPerson className="menuicon" size={30} />
                <div className="menuident">Profile</div>
              </Link>
            </>
          )}
        </div>
      </div>

      {/* hamburger menu */}
      <div
        style={{
          display: openMenu ? "flex" : "none",
        }}
        className="nav_fixed"
      >
        <AiFillCloseCircle
          onClick={() => setopenMenu(false)}
          className="nav_fixed_Canel"
        />

        {userInfo && userInfo.avatar && (
          <img src={userInfo.avatar} alt="farmImage" className="Mprofback" />
        )}

        <div className="mNameCont">
          {userInfo && userInfo.logisticsName && (
            <p className="Mname profName">{userInfo.logisticsName}</p>
          )}
          {userInfo && userInfo.farmName && (
            <p className="Mname profName">{userInfo.farmName}</p>
          )}
          {userInfo && userInfo.storeName && (
            <p className="Mname profName">{userInfo.storeName}</p>
          )}
          {userInfo && userInfo.companyName && (
            <p className="Mname profName">{userInfo.companyName}</p>
          )}
          {userInfo && userInfo.name && (
            <p className="Mname profName">{userInfo.name}</p>
          )}
          {userInfo && userInfo.username && (
            <p className="Mname profName">
              {userInfo.username ? "@" + userInfo.username : ""}
            </p>
          )}
        </div>
        <div className="scrollable">
          <div className="Mmenuitems">
            <Link
              className="Mmenuitem"
              to="/stores"
              onClick={() => setopenMenu(false)}
            >
              <MdStoreMallDirectory className="Mmenuicon" size={30} />
              <div className="Mmenuident">Search Store</div>
            </Link>
            <Link
              className="Mmenuitem"
              to="/farmersmarket"
              onClick={() => setopenMenu(false)}
            >
              <MdAgriculture
                className={`Mmenuicon ${
                  location.pathname === "/farmersmarket" ? "selected" : ""
                }`}
                size={30}
              />
              <div className="Mmenuident">Farmers' Market</div>
            </Link>
            <Link className="Mmenuitem" to="/logistics">
              <AiOutlineCar className="Mmenuicon" size={30} />
              <div className="Mmenuident">Logistics</div>
            </Link>
            <Link
              className="Mmenuitem"
              to="/companies"
              onClick={() => setopenMenu(false)}
            >
              <GoOrganization className="Mmenuicon" size={30} />
              <div className="Mmenuident">Wholesalers</div>
            </Link>
            {isAuthenticated() && (
              <>
                <Link
                  className="Mmenuitem"
                  to="/myprofile/MyCart"
                  onClick={() => setopenMenu(false)}
                >
                  <BsCart2 className="Mmenuicon" size={30} />
                  <div className="Mmenuident">My Cart</div>
                </Link>
                <Link
                  className="Mmenuitem"
                  to="/chat"
                  onClick={() => setopenMenu(false)}
                >
                  <IoChatbubblesOutline className="Mmenuicon" size={30} />
                  <div className="Mmenuident">Chat</div>
                </Link>
                <Link
                  className="Mmenuitem notification"
                  to="/myprofile/Notification"
                >
                  <IoMdNotificationsOutline className="Mmenuicon" size={30} />
                  <div className="Mmenuident">Notification</div>
                  <div className="notifyHead">{notificationCount} </div>
                </Link>
                <Link
                  className="Mmenuitem"
                  to="/myprofile"
                  onClick={() => setopenMenu(false)}
                >
                  <BsPerson className="Mmenuicon" size={30} />
                  <div className="Mmenuident">My Profile</div>
                </Link>
              </>
            )}
          </div>

          <div className="manuopt">
            {userInfo && userInfo.farmName && (
              <div>
                <Link to="/farmersmarket" onClick={() => setopenMenu(false)}>
                  <p className="manuopt">Farmer's Market</p>
                </Link>
                <div className="subopt">
                  <Link
                    to="/myprofile/MyProducts"
                    onClick={() => setopenMenu(false)}
                  >
                    <p className="manuopt1">My Products</p>
                  </Link>
                  {isAuthenticated() && (
                    <>
                      <Link to="/myprofile/MyCart">
                        <p
                          className="manuopt1"
                          onClick={() => setopenMenu(false)}
                        >
                          Notifications
                        </p>
                      </Link>
                      <Link
                        to="/myprofile/OrderList"
                        onClick={() => setopenMenu(false)}
                      >
                        <p className="manuopt1">Orders</p>
                      </Link>
                      <Link
                        to="/unavailables"
                        onClick={() => setopenMenu(false)}
                      >
                        <p className="manuopt1">What People Need</p>
                      </Link>
                      <Link to="/addaccount">
                        <p className="manuopt1">Add Account Details</p>
                      </Link>
                    </>
                  )}
                </div>
              </div>
            )}
            {userInfo && userInfo.storeName && (
              <div>
                <Link to="/myprofile">
                  <p className="manuopt" onClick={() => setopenMenu(false)}>
                    My Store
                  </p>
                </Link>
                <div className="subopt">
                  <Link to="/myprofile" onClick={() => setopenMenu(false)}>
                    Go to Profile
                  </Link>
                  <Link
                    to="/myprofile/MyProducts"
                    onClick={() => setopenMenu(false)}
                  >
                    <p className="manuopt1">My Products</p>
                  </Link>
                  <Link
                    to="/myprofile/AddLocation"
                    onClick={() => setopenMenu(false)}
                  >
                    <p className="manuopt1">Add Location</p>
                  </Link>
                  <Link
                    to="/uploadstoreproduct"
                    onClick={() => setopenMenu(false)}
                  >
                    <p className="manuopt1">Upload new Product</p>
                  </Link>
                  <Link to="/myprofile/Notification">
                    <p className="manuopt1" onClick={() => setopenMenu(false)}>
                      Notifications
                    </p>
                  </Link>
                  <Link
                    to="/myprofile/OrderList"
                    onClick={() => setopenMenu(false)}
                  >
                    <p className="manuopt1">Orders</p>
                  </Link>
                  <Link to="/addaccount">
                    <p className="manuopt1" onClick={() => setopenMenu(false)}>
                      Add Account Details
                    </p>
                  </Link>
                </div>

                <Link to="/myprofile/edit">
                  <p className="manuopt">Edit Profile</p>
                </Link>
              </div>
            )}
            {userInfo && userInfo.companyName && (
              <div>
                <Link to="/myprofile">
                  <p className="manuopt" onClick={notify}>
                    My Company
                  </p>
                </Link>
                <div className="subopt">
                  <Link to="/myprofile" onClick={() => setopenMenu(false)}>
                    Go to Profile
                  </Link>
                  <Link
                    to="/myprofile/MyProducts"
                    onClick={() => setopenMenu(false)}
                  >
                    <p className="manuopt1">My Products</p>
                  </Link>
                  <Link
                    to="/myprofile/AddLocation"
                    onClick={() => setopenMenu(false)}
                  >
                    <p className="manuopt1">Add Location</p>
                  </Link>
                  <Link
                    to="/uploadcompanyproduct"
                    onClick={() => setopenMenu(false)}
                  >
                    <p className="manuopt1">Upload new Product</p>
                  </Link>
                  <Link to="/myprofile/Notification">
                    <p className="manuopt1" onClick={() => setopenMenu(false)}>
                      Notifications
                    </p>
                  </Link>
                  <Link
                    to="/myprofile/CompanyOrder"
                    onClick={() => setopenMenu(false)}
                  >
                    <p className="manuopt1">Orders</p>
                  </Link>
                  <Link to="/addaccount">
                    <p className="manuopt1" onClick={() => setopenMenu(false)}>
                      Add Account Details
                    </p>
                  </Link>
                </div>
              </div>
            )}
            {userInfo && userInfo.logisticsName && (
              <div>
                <Link to="/myprofile" onClick={() => setopenMenu(false)}>
                  <p className="manuopt" onClick={notify}>
                    Dashboard
                  </p>
                </Link>
                <div className="subopt">
                  <Link>
                    <p className="manuopt1">My Vehicles</p>
                  </Link>
                  <Link>
                    <p className="manuopt1" onClick={notify}>
                      Notifications
                    </p>
                  </Link>
                  <Link
                    to="/myprofile/OrderList"
                    onClick={() => setopenMenu(false)}
                  >
                    <p className="manuopt1">Orders</p>
                  </Link>
                  <Link to="/addaccount">
                    <p className="manuopt1">Add Account Details</p>
                  </Link>
                </div>
              </div>
            )}
            {userInfo && userInfo.name && (
              <div>
                <Link>
                  <p className="manuopt" onClick={notify}>
                    Dashboard
                  </p>
                </Link>
                <div className="subopt">
                  {/* <Link>
                    <p className="manuopt1">My Products</p>
                  </Link> */}
                  <Link>
                    <p className="manuopt1" onClick={notify}>
                      Notifications
                    </p>
                  </Link>
                  <Link
                    to="/myprofile/OrderList"
                    onClick={() => setopenMenu(false)}
                  >
                    <p className="manuopt1">Orders</p>
                  </Link>
                  <Link to="/addaccount">
                    <p className="manuopt1">Add Account Details</p>
                  </Link>
                </div>
              </div>
            )}

            {/* <Link onClick={notify}>
              <p className="manuopt">Profile Settings</p>
            </Link> */}
            <Link to="/tandc">
              <p className="manuopt1">Terms and Condition</p>
            </Link>
            <Link to="/referral">
              <p className="manuopt1">Referral Terms and Benefits</p>
            </Link>
            <Link to="/return">
              <p className="manuopt1">Return Policy</p>
            </Link>
            <Link to="/faqs">
              <p className="manuopt1">Frequently asked questions (FAQs)</p>
            </Link>
            <Link onClick={logout} to="/" className="menuitemhere">
              <p className="manuoptlog">Logout</p>
              <BiLogOut />
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
