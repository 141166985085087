import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { UserContext } from "../context/UserContext";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

function EditCompanyLocation({ setShowLocationForm }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [closed, setClosed] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { setUserInfo } = useContext(UserContext);

  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 5000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  const notify = (message, title = "Success Message") => {
    toastr.success(message, title);
  };

  const displayError1 = () => {
    toastr.warning("This might take some time.", "Please be patient");
  };

  const displayError = (error) => {
    toastr.error(
      error,
      "Error: Please check and fill the form again. File means the image you uploaded"
    );
  };

  useEffect(() => {
    // Fetch the existing location data
    const fetchLocationData = async () => {
      try {
        const response = await axios.get(`/api/v1/company/location/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const location = response.data;
        setAddress(location.address);
        setCity(location.city);
        setLongitude(location.coordinates.coordinates[0]);
        setLatitude(location.coordinates.coordinates[1]);
        setClosed(location.closed);
      } catch (error) {
        displayError("An error occurred while fetching the location data.");
      }
    };

    fetchLocationData();
  }, [id]);

  const getUserLocation = () => {
    displayError1();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLongitude(position.coords.longitude);
          setLatitude(position.coords.latitude);
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(""); // Clear the success message
    setError("");

    // Create a location object
    const locationData = {
      address,
      city,
      coordinates: [parseFloat(longitude), parseFloat(latitude)],
      closed,
    };

    try {
      const response = await axios({
        url: `/api/v1/company/location/${id}`,
        withCredentials: true,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: locationData,
      });

      setSuccess("Location updated successfully.");
      notify("Store Location updated successfully.");

      // After the location is updated successfully, perform the redirect
      try {
        const response = await axios({
          url: "api/v1/company/profile",
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const userInfoData = response.data;
        setUserInfo(userInfoData);
        navigate("/myprofile");
      } catch (error) {
        displayError("Error redirecting.");
      }
    } catch (error) {
      setError("An error occurred while updating the location.");
      displayError("An error occurred while updating the location.");
    }
  };

  return (
    <div className="formContainer">
      <form className="post_blog" onSubmit={handleSubmit}>
        <div className="waitlist_post">
          <label className="form_label" htmlFor="address">
            Address:
          </label>
          <input
            type="text"
            id="address"
            value={address}
            className="form_input"
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="waitlist_post">
          <label className="form_label" htmlFor="city">
            City:
          </label>
          <input
            type="text"
            id="city"
            value={city}
            className="form_input"
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
        <div className="waitlist_post">
          <label className="form_label" htmlFor="longitude">
            Longitude:
          </label>
          <input
            type="text"
            id="longitude"
            value={longitude}
            className="form_input"
            onChange={(e) => setLongitude(e.target.value)}
          />
        </div>
        <div className="waitlist_post">
          <label className="form_label" htmlFor="latitude">
            Latitude:
          </label>
          <input
            type="text"
            id="latitude"
            value={latitude}
            className="form_input"
            onChange={(e) => setLatitude(e.target.value)}
          />
        </div>
        <div className="waitlist_post">
          <label className="form_label" htmlFor="closed">
            Closed:
          </label>
          <input
            type="checkbox"
            id="closed"
            checked={closed}
            onChange={(e) => setClosed(e.target.checked)}
          />
        </div>
        <div className="post_bts">
          <button className="post_bt" type="button" onClick={getUserLocation}>
            Get Current Location
          </button>
          <button className="post_bt" type="submit">
            Update Location
          </button>
        </div>
        <>{error}</>
        <>{success}</>
      </form>
    </div>
  );
}

export default EditCompanyLocation;
