// src/components/RegistrationProgress.js
import React from "react";
import "../styles/RegistrationProgress.css";

const RegistrationProgress = ({ userInfo }) => {
  let completedSteps = 0;
  const totalSteps = 3; // Increased total steps to account for schedule checks

  const userType = userInfo.name
    ? "user"
    : userInfo.companyName
    ? "company"
    : userInfo.logisticsName
    ? "logistics"
    : userInfo.storeName
    ? "store"
    : "farmer";

  if (userInfo.products && userInfo.products.length > 0) {
    completedSteps++;
  }

  if (userInfo.vehicles && userInfo.vehicles.length > 0) {
    completedSteps++;
  }

  if (
    (userType === "store" &&
      userInfo.storeLocations &&
      userInfo.storeLocations.length > 0) ||
    (userType === "company" &&
      userInfo.companyLocations &&
      userInfo.companyLocations.length > 0) ||
    (userType === "farmer" &&
      userInfo.products &&
      userInfo.products.length > 0) ||
    (userType === "logistics" &&
      userInfo.officeLocations &&
      userInfo.officeLocations.length > 0) ||
    userType === "user"
  ) {
    completedSteps++;
  }

  if (
    (userType === "store" &&
      userInfo.storeHours &&
      userInfo.storeHours.length === 7) ||
    (userType === "company" &&
      userInfo.schedule &&
      userInfo.schedule.length === 7) ||
    (userType === "logistics" &&
      userInfo.schedule &&
      userInfo.schedule.length === 7)
  ) {
    completedSteps++;
  }

  let progressPercentage = (completedSteps / totalSteps) * 100;

  const progressColor =
    progressPercentage === 100
      ? ""
      : progressPercentage >= 50
      ? "yellow"
      : "red";

  const getPendingStep = () => {
    if (
      userType === "store" &&
      (!userInfo.products || userInfo.products.length === 0)
    ) {
      return "Upload your products";
    }
    if (
      userType === "store" &&
      (!userInfo.storeLocations || userInfo.storeLocations.length === 0)
    ) {
      return "Add your store locations";
    }
    if (
      userType === "store" &&
      (!userInfo.storeHours || userInfo.storeHours.length !== 7)
    ) {
      return "Add your schedule for all days go to Edit Profile to do this";
    }
    if (
      userType === "company" &&
      (!userInfo.products || userInfo.products.length === 0)
    ) {
      return "Upload your products";
    }
    if (
      userType === "company" &&
      (!userInfo.companyLocations || userInfo.companyLocations.length === 0)
    ) {
      return "Add your company locations";
    }
    if (
      userType === "company" &&
      (!userInfo.schedule || userInfo.schedule.length !== 7)
    ) {
      return "Add your company schedule for all days";
    }
    if (
      userType === "farmer" &&
      (!userInfo.products || userInfo.products.length === 0)
    ) {
      return "Upload your products";
    }
    if (
      userType === "logistics" &&
      (!userInfo.vehicles || userInfo.vehicles.length === 0)
    ) {
      return "Upload your vehicles";
    }
    if (
      userType === "logistics" &&
      (!userInfo.officeLocations || userInfo.officeLocations.length === 0)
    ) {
      return "Add your office locations";
    }
    if (
      userType === "logistics" &&
      (!userInfo.schedule || userInfo.schedule.length !== 7)
    ) {
      return "Add your logistics schedule for all days";
    }
    return "You have completed all registration steps";
  };

  if (userType === "user") {
    progressPercentage = 100;
  }

  if (progressPercentage === 100) {
    return null;
  }

  return (
    <div className="registration-progress">
      <h4>Registration Progress</h4>
      <div className="progress-bar">
        <div
          className={`progress ${progressColor}`}
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
      <p>{progressPercentage}% complete</p>
      <p>{getPendingStep()}</p>
    </div>
  );
};

export default RegistrationProgress;
