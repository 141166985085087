import React, { useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { BASE_URL } from "../utils/endpoints";

function DeleteAccountAndChats() {
  const { userInfo } = useContext(UserContext);
  const navigate = useNavigate();

  const handleDeleteChats = () => {
    if (
      window.confirm(
        "Are you sure you want to delete all your chats? This action cannot be undone."
      )
    ) {
      axios
        .delete(`/api/v1/chat/user/${userInfo._id}/chats`)
        .then((response) => {
          toastr.success("Chats deleted successfully");
        })
        .catch((error) => {
          toastr.error("Failed to delete chats");
        });
    }
  };

  const handleDeleteAccount = () => {
    if (
      window.confirm(
        "Are you sure you want to delete your account? This action cannot be undone."
      )
    ) {
      const deleteEndpointMap = {
        farm: BASE_URL + "/api/v1/farm/delete",
        logistics: BASE_URL + "/api/v1/logistics/delete",
        store: BASE_URL + "/api/v1/store/delete",
        company: BASE_URL + "/api/v1/company/delete",
        user: BASE_URL + "/api/v1/user/delete",
      };

      const endpoint =
        deleteEndpointMap[userInfo.userType] || deleteEndpointMap["user"];

      axios
        .delete(endpoint, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          toastr.success("Account deleted successfully");
          localStorage.removeItem("userInfo");
          navigate("/signin");
        })
        .catch((error) => {
          console.error("Failed to delete account " + error.message);
          toastr.error("Failed to delete account " + error.message);
        });
    }
  };

  return (
    <div className="middleDash">
      <p>
        <strong>Warning:</strong> Deleting your account is a permanent action.
        If you delete your account, you have 30 days to recover it (instructions
        have been sent to your email) . Deleted chats cannot be recovered, and
        all your chat history will be lost forever.
      </p>
      <button onClick={handleDeleteChats} className="moneyCont1">
        Delete All Chats
      </button>
      <button> &nbsp; &nbsp;</button>
      <button onClick={handleDeleteAccount} className="moneyCont2">
        Delete Account
      </button>
    </div>
  );
}

export default DeleteAccountAndChats;
