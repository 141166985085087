import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import Navbar from "./Navbar";

function StorePEdit(props) {
  const { id } = useParams();
  const { setUserInfo } = useContext(UserContext);
  const navigate = useNavigate();

  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [measuringScale, setMeasuringScale] = useState("");
  const [price, setPrice] = useState("");
  // const [availableQuantity, setAvailableQuantity] = useState("");
  const [inStock, setInStock] = useState(true); // Default value
  const [images, setImages] = useState([]);

  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 5000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  const notify = () => {
    toastr.success("Product updated successfully.", "Success Message");
  };

  const displayError = (error) => {
    toastr.error(error, "Error: Please check and fill the form again.");
  };

  

  useEffect(() => {
    axios
      .get("api/v1/storeproducts/wahala/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        const {
          productName,
          productDescription,
          measuringScale,
          price,
          // availableQuantity,
          inStock,
        } = response.data;
        setProductName(productName);
        setProductDescription(productDescription);
        setMeasuringScale(measuringScale);
        setPrice(price);
        // setAvailableQuantity(availableQuantity);
        setInStock(inStock);
      })
      .catch((error) => {
        displayError(error.response.data);
      });
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("productName", productName);
    formData.append("productDescription", productDescription);
    formData.append("measuringScale", measuringScale);
    formData.append("price", price);
    // formData.append("availableQuantity", availableQuantity);
    formData.append("inStock", inStock);

    if (images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
    }

    try {
      const response = await axios.put(`api/v1/storeproducts/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 201) {
        notify();
        fetchUserProfile(); // Update user profile after successful update
      } else {
        displayError("Failed to update product.");
      }
    } catch (error) {
      displayError(error.response.data);
    }
  };

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get("api/v1/store/profile", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setUserInfo(response.data);
      navigate("/myprofile");
    } catch (error) {
      displayError(error.response.data.message);
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
  };

  return (
    <div>
      <Navbar />
      <div className="container1">
        <form className="post_blog" onSubmit={handleSubmit}>
          <div className="waitlist_post">
            <label className="form_label">Product Name</label>
            <input
              type="text"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              className="form_input"
              placeholder="Enter Product Name"
              required
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">Product Description</label>
            <input
              type="text"
              value={productDescription}
              onChange={(e) => setProductDescription(e.target.value)}
              className="form_input"
              placeholder="Enter Product Description"
              required
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">Measuring Scale</label>
            <input
              type="text"
              value={measuringScale}
              onChange={(e) => setMeasuringScale(e.target.value)}
              className="form_input"
              placeholder="Enter Measuring Scale"
              required
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">Per Unit Price</label>
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="form_input"
              placeholder="Enter Per Unit Price"
              required
            />
          </div>
          {/* <div className="waitlist_post">
            <label className="form_label">Available Quantity</label>
            <input
              type="text"
              value={availableQuantity}
              onChange={(e) => setAvailableQuantity(e.target.value)}
              className="form_input"
              placeholder="Enter Available Quantity"
              required
            />
          </div> */}
          <div className="waitlist_post">
            <label className="form_label">In Stock</label>
            <input
              type="checkbox"
              checked={inStock}
              onChange={(e) => setInStock(e.target.checked)}
              className="form_input"
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">Product Images</label>
            <input
              type="file"
              onChange={handleImageChange}
              multiple
              className="form_input"
            />
          </div>
          <button className="pos_bt" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default StorePEdit;
