import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import "../styles/waitlist.css";
import { BiArrowBack } from "react-icons/bi";
import Navbar from "./Navbar";

function CompanyUpload() {
  const { userInfo, setUserInfo } = useContext(UserContext);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showCategoryPopup, setShowCategoryPopup] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [categories, setCategories] = useState([]);

  const navigate = useNavigate();

  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [measuringScale, setMeasuringScale] = useState("");
  const [price, setPrice] = useState("");
  const [commission, setCommission] = useState("");
  const [availableQuantity, setAvailableQuantity] = useState("");
  const [images, setImages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 5000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  const notify = (message, title = "Success Message") => {
    toastr.success(message, title);
  };

  const displayError1 = () => {
    toastr.warning("This might take some time.", "Please be patient");
  };

  const displayError = (error) => {
    toastr.error(
      error,
      "Error: Please check and fill the form again. File means the image you uploaded"
    );
  };

  useEffect(() => {
    // Fetch categories here and update the 'categories' state
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `api/v1/companyproducts/categories/${userInfo.slug}`
        );
        // console.log(response.data)
        setCategories(response.data.categories);
      } catch (error) {
        // console.error(error);
      }
    };

    fetchCategories();
  }, [userInfo.slug]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    displayError1();
    setIsLoading(true);

    let formData = new FormData();
    if (images) {
      for (let i = 0; i < images.length; i++) {
        formData.append("images", images[i]);
      }
    }

    formData.append("productName", productName);
    formData.append("productDescription", productDescription);
    formData.append("measuringScale", measuringScale);
    formData.append("price", price);
    formData.append("commission", commission);
    formData.append("availableQuantity", availableQuantity);
    formData.append("category", selectedCategory);

    try {
      await axios.post("api/v1/companyproducts", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      notify("Product uploaded successfully.");
      newRedirect();
    } catch (error) {
      displayError(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const newRedirect = async () => {
    try {
      const response = await axios.get("api/v1/company/profile", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const userInfoData = response.data;
      setUserInfo(userInfoData);
      navigate("/myprofile/MyProducts");
    } catch (error) {
      // console.error(error);
      displayError(error.response.data.message);
    }
  };

  const handleCreateCategory = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "api/v1/companyproducts/category",
        {
          name: newCategoryName,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setCategories([...categories, response.data]);
      setNewCategoryName("");
      setShowCategoryPopup(false);
      notify("Category created successfully.");
    } catch (error) {
      console.error(error);
      // displayError(error.message);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="container1">
        <div className="goBack">
          <button onClick={() => navigate(-1)} className="goBack">
            <BiArrowBack /> Go back
          </button>
        </div>
        <br />
        <form className="post_blog" onSubmit={handleSubmit}>
          <div className="waitlist_post">
            <label className="form_label">Product Name</label>
            <input
              type="text"
              onChange={(e) => setProductName(e.target.value)}
              value={productName}
              className="form_input"
              placeholder={"Enter Product Name"}
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">Product Description</label>
            <input
              type="text"
              onChange={(e) => setProductDescription(e.target.value)}
              value={productDescription}
              className="form_input"
              placeholder={"Enter product description here."}
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">Measuring Scale</label>
            <input
              type="text"
              onChange={(e) => setMeasuringScale(e.target.value)}
              value={measuringScale}
              className="form_input"
              placeholder={
                "Enter Measuring Scale e.g. Tonnes, congos, crates..."
              }
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">Category</label>
            <select
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value);
              }}
              className="form_input"
            >
              <option value="">Select a category...</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>
            {showCategoryPopup && (
              <div className="form_input">
                <input
                  type="text"
                  onChange={(e) => setNewCategoryName(e.target.value)}
                  value={newCategoryName}
                  className="form_input"
                  placeholder={"Enter the name of the new category here"}
                />
                <button className="lbutton" onClick={handleCreateCategory}>
                  Create Category
                </button>
                <button
                  className="lbutton"
                  onClick={() => setShowCategoryPopup(false)}
                >
                  Cancel
                </button>
              </div>
            )}
            {!showCategoryPopup && selectedCategory === "" && (
              <button
                className="lbutton"
                onClick={() => setShowCategoryPopup(true)}
              >
                Add New Category
              </button>
            )}
          </div>
          <div className="waitlist_post">
            <label className="form_label">Per Unit Price</label>
            <input
              type="number"
              onChange={(e) => setPrice(e.target.value)}
              value={price}
              className="form_input"
              placeholder={
                "How much is the last price do you intend selling per scale you entered above"
              }
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">Commission</label>
            <input
              type="number"
              onChange={(e) => setCommission(e.target.value)}
              value={commission}
              className="form_input"
              placeholder={"What will you pay FarmyApp for every unit of this product sold"}
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">Available Quantity</label>
            <input
              type="text"
              onChange={(e) => setAvailableQuantity(e.target.value)}
              value={availableQuantity}
              className="form_input"
              placeholder={
                "What quantity of this product will be available for sale"
              }
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">Product Images</label>
            <input
              type="file"
              onChange={(e) => setImages(e.target.files)}
              className="custom-file-input"
              multiple
              accept="image/*"
            />
            <br />
            <div className="image-preview-container">
              {images &&
                Array.from(images).map((image, index) => (
                  <div key={index} className="image-preview">
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Images ${index}`}
                    />
                  </div>
                ))}
            </div>
          </div>
          <button className="pos_bt" disabled={isLoading}>
            {isLoading ? <span className="loading-spinner"></span> : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default CompanyUpload;
