import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../static/logo.png";
import menuicon from "../static/menuicon.svg";

const navItems = [
  {
    title: "Explore",
    route: "/farmersmarket",
  },
  {
    title: "Login",
    route: "/signin",
  },
  {
    title: "Create account",
    route: "/signup",
  },
];

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleToggleMenu = () => {
    // alert("toggle menu");
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {});

  return (
    <header className="headerContainer">
      <div className="headerSubContainer">
        <div className="headerLogo">
          <img src={logo} alt="Logo" />
        </div>
        <nav className={`nav ${toggleMenu ? "" : "hidden"}`}>
          {navItems.map((item, index) => (
            <NavLink
              key={item.route}
              to={item.route}
              className={({ isActive }) =>
                `nav-item ${isActive ? "active" : ""}`
              }
              end
            >
              <p>{item.title}</p>
            </NavLink>
          ))}
        </nav>
        <button className="menu-button" onClick={handleToggleMenu}>
          <img src={menuicon} alt="Farmyapp Menu icon" />
        </button>
      </div>
    </header>
  );
};

export default Header;
