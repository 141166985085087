import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { PaystackButton } from "react-paystack";
import { BiArrowBack } from "react-icons/bi";
import { PUBLIC_KEY } from "../config";

import "../styles/waitlist.css";
import Navbar from "./Navbar";

function Deposit() {
  const { userInfo, setUserInfo } = useContext(UserContext);
  const [userType, setUserType] = useState("");
  const navigate = useNavigate();

  const [totalAmount, setTotalAmount] = useState("");
  const [transferAmount, setTransferAmount] = useState("");
  const [accountName, setAccountName] = useState("");

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    const numericText = inputText.replace(/\D/g, ""); // Remove non-numeric characters
    setTransferAmount(numericText);
  };

  const handleInputChange2 = (e) => {
    const inputText = e.target.value;
    setAccountName(inputText);
  };

  const handleInputChange1 = (e) => {
    const inputText = e.target.value;
    const numericText = inputText.replace(/\D/g, ""); // Remove non-numeric characters
    setTotalAmount(numericText);
  };

  const publicKey = PUBLIC_KEY;

  useEffect(() => {
    if (userInfo.logisticsName) {
      setUserType("logistics");
    } else if (userInfo.storeName) {
      setUserType("store");
    } else if (userInfo.companyName) {
      setUserType("company");
    } else if (userInfo.farmName) {
      setUserType("farm");
    } else {
      setUserType("user");
    }
  }, [userInfo]);

  const config = {
    reference: new Date().getTime().toString(),
    email: userInfo.email,
    amount: totalAmount * 100,
    publicKey: publicKey,
  };

  const onSuccess = (reference) => {
    console.log("Payment reference:", reference);
    makePayment()
      .then(async () => {
        const profileResponse = await axios({
          url: `api/v1/${userType}/profile`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          method: "GET",
        }).catch((error) => {
          console.error("Error fetching profile:", error);
          throw error;
        });

        const userInfoData = profileResponse.data;
        setUserInfo(userInfoData);
        window.location = "/myprofile";
      })
      .catch((error) => {
        console.error("Error handling successful payment:", error);
        // Handle the error, e.g., showing a message to the user
      });
  };

  const onClose = () => {
    console.log("Payment closed");
    navigate("/deposit");
  };

  const componentProps = {
    ...config,
    text: "Pay with Bank",
    onSuccess: onSuccess,
    onClose: onClose,
  };

  const makePayment = async () => {
    try {
      const response = await axios({
        url: "/api/v1/general/deposit",
        method: "POST",
        data: { amount: totalAmount },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log(response.data); // Log the response to check if it's successful
    } catch (error) {
      console.error("error making payment: ", error);
      throw error;
    }
  };

  return (
    <div>
      <Navbar />
      <div className="container1">
        <div className="goBack">
          <button onClick={() => navigate(-1)} className="goBack">
            <BiArrowBack /> Go back
          </button>
        </div>
        <br />
        <div className="container3">
          <br />
          <br />
          <h3>
            You can make a transfer directly to our bank account and have your
            wallet credited before you finish adding products to your cart.
          </h3>
          <br />
          <h4>Account name: Phi Agribusiness Agritech Development Ent</h4>
          <h4>Account number: 2034896198</h4>
          <h4>Bank name: First Bank Plc</h4>
          <div className="waitlist_post">
            <label htmlFor="amount" className="form_label">
              Amount
            </label>
            <input
              type="text"
              id="amount"
              name="amount"
              onChange={handleInputChange}
              value={transferAmount}
              className="form_input"
              placeholder="How much did you transfer?"
            />
          </div>
          <div className="waitlist_post">
            <label htmlFor="amount" className="form_label">
              Account Name
            </label>
            <input
              type="text"
              id="accountName"
              name="accountName"
              onChange={handleInputChange2}
              value={accountName}
              className="form_input"
              placeholder="Account name you deposited with?"
            />
          </div>
          <button className="order-details-btn2">Confirm Transfer</button>
        </div>
        <br />
        <div className="post_blog">
          <h3>Make online transaction Here</h3>
          <div className="waitlist_post">
            <label htmlFor="amount" className="form_label">
              Amount
            </label>
            <input
              type="text"
              id="amount"
              name="amount"
              onChange={handleInputChange1}
              value={totalAmount}
              className="form_input"
              placeholder="How much do you intend to deposit?"
            />
          </div>
          <PaystackButton {...componentProps} className="order-details-btn2" />
        </div>
      </div>
    </div>
  );
}

export default Deposit;
